import { HttpClient } from '@angular/common/http';
import {
  MinimalImportableKey,
  ManagedKeyInfo,
  TKeyType,
  IKey,
} from '@veramo/core';
import { AbstractKeyManagementSystem } from '@veramo/key-manager';
import { firstValueFrom } from 'rxjs';

export class RemoteKeyManagementSystem extends AbstractKeyManagementSystem {
  private endpoint = 'kms';

  constructor(private httpClient: HttpClient) {
    super();
  }

  override importKey(args: MinimalImportableKey): Promise<ManagedKeyInfo> {
    throw new Error('Method not implemented.');
  }
  override listKeys(): Promise<ManagedKeyInfo[]> {
    return firstValueFrom(this.httpClient.get<ManagedKeyInfo[]>(this.endpoint));
  }

  override createKey(args: {
    type: TKeyType;
    meta?: any;
  }): Promise<ManagedKeyInfo> {
    return firstValueFrom(
      this.httpClient.post<ManagedKeyInfo>(this.endpoint, args)
    );
  }

  override deleteKey(args: { kid: string }): Promise<boolean> {
    return firstValueFrom(
      this.httpClient.delete<boolean>(`${this.endpoint}/keys/${args.kid}`)
    );
  }

  override sign(args: {
    [x: string]: any;
    keyRef: Pick<IKey, 'kid'>;
    algorithm?: string | undefined;
    data: Uint8Array;
  }): Promise<string> {
    //TODO: in the best case, only the hash for the signature will be transferred.
    return firstValueFrom(
      this.httpClient.post<{ value: string }>(`${this.endpoint}/sign`, args)
    ).then((res) => res.value);
  }

  override sharedSecret(args: {
    myKeyRef: Pick<IKey, 'kid'>;
    theirKey: Pick<IKey, 'type' | 'publicKeyHex'>;
  }): Promise<string> {
    //we don't really need this for now.
    throw new Error('Method not implemented.');
  }
}
