import { Component, Input, OnInit } from '@angular/core';
import { CredentialsSupportedDisplay } from '@sphereon/oid4vci-common';
import { UniqueVerifiableCredential } from '@veramo/core';
import { CredentialsService } from '../credentials.service';
import { DisplayService } from '../display.service';

@Component({
  selector: 'bcrl-credentials-card',
  templateUrl: './credentials-card.component.html',
  styleUrl: './credentials-card.component.scss',
})
export class CredentialsCardComponent implements OnInit {
  @Input() credential!: UniqueVerifiableCredential;

  display!: CredentialsSupportedDisplay[];

  expire?: string;

  status?: string;

  height?: string;

  width?: string;

  type?: string;

  constructor(
    private credentialsService: CredentialsService,
    private displayService: DisplayService
  ) {}

  ngOnInit(): void {
    this.display = this.displayService.getDisplay({
      hash: this.credential.hash,
    });
    this.credentialsService
      .status(this.credential.verifiableCredential)
      .then((status) => (this.status = status));
    this.expire = this.credentialsService.expire(
      this.credential.verifiableCredential
    );
  }
}
