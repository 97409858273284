import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CardComponent } from './card/card.component';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { CredentialValuesComponent } from './credential-values/credential-values.component';
import { MatListModule } from '@angular/material/list';
import { CredentialsShowRawComponent } from './credentials-show-raw/credentials-show-raw.component';
import { HighlightModule } from 'ngx-highlightjs';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

const components = [
  CardComponent,
  CredentialValuesComponent,
  CredentialsShowRawComponent,
];

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatListModule,
    MatButtonModule,
    MatDialogModule,
    HighlightModule,
  ],
  declarations: components,
  exports: components,
})
export class SharedFrontendModule {}
