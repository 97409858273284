import { Injectable } from '@angular/core';
import { AgentService, didProviderKeys, didResolver } from '../agent.service';
import { IIdentifier } from '@veramo/core';
import {
  CheckLinkedDomain,
  PresentationExchange,
  RPRegistrationMetadataPayload,
  VerifiedAuthorizationRequest,
  PresentationDefinitionWithLocation,
} from '@sphereon/did-auth-siop';
import { v4 as uuidv4 } from 'uuid';
import {
  W3CVerifiableCredential,
  IVerifiableCredential,
  CredentialMapper,
} from '@sphereon/ssi-types';
import {
  OID4VP,
  OpSession,
  VerifiableCredentialsWithDefinition,
  VerifiablePresentationWithDefinition,
} from '@sphereon/ssi-sdk.siopv2-oid4vp-op-auth';
import { ContactsService } from '../contacts/contacts.service';
import { getKey } from '@sphereon/ssi-sdk-ext.did-utils';
import { Router } from '@angular/router';

export interface Group {
  key: string;
  purpose: string;
  value: IVerifiableCredential[];
}

export interface Request {
  client: string;
  definition: PresentationDefinitionWithLocation;
  requests: Group[];
}

@Injectable({
  providedIn: 'root',
})
export class PresentationService {
  session!: OpSession;
  url!: URL;
  verifiedAuthorizationRequest!: VerifiedAuthorizationRequest;
  registration!: RPRegistrationMetadataPayload;
  res?: Request;

  /**
   * The constructor function initializes private variables for the agent service, contacts service,
   * and router.
   * @param {AgentService} agentService - An instance of the AgentService class, which is responsible
   * for handling agent-related operations.
   * @param {ContactsService} contactsService - The `contactsService` parameter is an instance of the
   * `ContactsService` class. It is used to interact with the contacts data and perform operations such
   * as retrieving, creating, updating, and deleting contacts.
   * @param {Router} router - The router parameter is an instance of the Router class, which is used
   * for navigationg between different routes in an Angular application. It allows you to
   * programmatically navigate to different views or components based on user actions or application
   * logic.
   */
  constructor(
    private agentService: AgentService,
    private contactsService: ContactsService,
    private router: Router
  ) {}

  logRequest(accepted = false) {
    this.persistRequest(
      this.verifiedAuthorizationRequest.jwt,
      accepted,
      this.res!.definition.definition.purpose!
    );
    this.router.navigate(['/']);
  }

  /**
   * Parse the auth request
   * @param qr
   */
  async authRequest(qr: string) {
    this.url = new URL(decodeURIComponent(qr.split('?request_uri=')[1].trim()));
    const sessionId = uuidv4();
    this.session = await this.agentService.agent
      .siopGetOPSession({ sessionId })
      .catch(async () =>
        this.agentService.agent.siopRegisterOPSession({
          sessionId,
          op: {
            checkLinkedDomains: CheckLinkedDomain.NEVER, // fixme: check whether it works and enable
            resolveOpts: {
              resolver: didResolver,
            },
            supportedDIDMethods: didProviderKeys,
          },
          requestJwtOrUri: qr,
        })
      );
    await this.session.getAuthorizationRequest().then(
      (res) => (this.verifiedAuthorizationRequest = res),
      (err) => {
        console.log(err);
        if (err.message.includes('invalid_jwt')) {
          alert('Request is expired, please use a new one');
        } else {
          this.logRequest();
        }
        this.router.navigate(['/']);
      }
    );
    if (!this.verifiedAuthorizationRequest) return;
    this.registration =
      (await this.verifiedAuthorizationRequest.authorizationRequest.getMergedProperty(
        'registration'
      )) as RPRegistrationMetadataPayload;

    //we can use the issuer attribute, but it will not be the same as the one from the issuance process.
    const verifier = await this.session
      .getAuthorizationRequest()
      .then((res) => res.redirectURI.split('/siop/')[0]);
    if (!(await this.contactsService.confirm(verifier))) {
      this.logRequest();
      return;
    }
    this.selectRequiredCredentials(verifier).catch((err) => {
      alert(err);
      this.router.navigate(['/credentials']);
    });
  }

  async selectRequiredCredentials(verifier: string): Promise<void> {
    const clientId: string | undefined =
      await this.verifiedAuthorizationRequest.authorizationRequest.getMergedProperty<string>(
        'client_id'
      );
    const correlationId: string | undefined = clientId
      ? clientId.startsWith('did:')
        ? clientId
        : `${new URL(clientId).protocol}//${new URL(clientId).hostname}`
      : undefined;
    if (correlationId) {
      this.contactsService.addDid(correlationId, verifier);
    }

    // TODO SIOPv2 and OID4VP are separate. In other words SIOP doesn't require OID4VP. This means that presentation definitions are optional.
    // TODO In that case we should skip the required credentials and send the response
    if (
      !this.verifiedAuthorizationRequest.presentationDefinitions ||
      this.verifiedAuthorizationRequest.presentationDefinitions.length === 0
    ) {
      return Promise.reject(Error('No presentation definitions present'));
    }
    if (this.verifiedAuthorizationRequest.presentationDefinitions.length > 1) {
      return Promise.reject(Error('Multiple presentation definitions present'));
    }
    // const params = {
    //   verifier: this.contactsService.getByUrl(this.url.hostname)?.name,
    //   // TODO currently only supporting 1 presentation definition
    //   presentationDefinition: presentationDefinitionWithLocation.definition,
    //   format,
    //   subjectSyntaxTypesSupported,
    // };
    // TODO: we need to save the hash so we know how to find the correct template
    const hashes: string[] = [];
    const allVerifiableCredentials = (await this.agentService.agent
      .dataStoreORMGetVerifiableCredentials()
      .then((vcs) =>
        vcs.map((vc) => {
          hashes.push(vc.hash);
          return vc.verifiableCredential;
        })
      )) as W3CVerifiableCredential[];
    // we need to add a credentials that match with the definition. We can go via the filter or maybe the pattern. Need to look at the docs what works best.
    const pex = new PresentationExchange({
      allVerifiableCredentials,
    });

    for (const definition of this.verifiedAuthorizationRequest
      .presentationDefinitions) {
      await pex
        .selectVerifiableCredentialsForSubmission(definition.definition)
        .then(
          (results) => {
            this.res = {
              requests: [],
              definition,
              client: this.verifiedAuthorizationRequest.issuer,
            };
            results.matches!.forEach((match) => {
              if (
                !this.res!.requests.find((group) => group.key === match.name!)
              ) {
                const purpose = definition.definition.input_descriptors.find(
                  (input) => input.name === match.name
                )!.purpose!;
                this.res!.requests.push({
                  key: match.name!,
                  value: [],
                  purpose,
                });
              }
              const index = this.res!.requests.findIndex(
                (group) => group.key === match.name!
              );
              const vcs = match.vc_path.map((vcPath) => {
                const index = parseInt(vcPath.match(/\[(\d+)\]/)![1]);
                const jwt = results.verifiableCredential![index]!;
                return CredentialMapper.toUniformCredential(jwt);
              });
              this.res!.requests[index].value.push(...vcs);
            });
          },
          () => {
            alert('found no matching credential');
            this.router.navigate(['/']);
          }
        );
      // after we got the results, we can match it with the hashes and need to connect them
    }
  }

  /**
   * The function `sendResponse` sends an authorization response with verifiable presentations and
   * response signer options.
   * @param {VerifiableCredentialsWithDefinition[]} credentials - An array of
   * VerifiableCredentialsWithDefinition objects. These objects contain the verifiable credentials
   * along with their corresponding definitions.
   * @returns the result of the `session.sendAuthorizationResponse()` method.
   */
  async sendResponse(credentials: VerifiableCredentialsWithDefinition[]) {
    const identifiers: IIdentifier[] =
      await this.session.getSupportedIdentifiers();
    if (!identifiers || identifiers.length === 0) {
      throw Error(
        `No DID methods found in agent that are supported by the relying party`
      );
    }
    let presentationsAndDefs:
      | VerifiablePresentationWithDefinition[]
      | undefined;
    let identifier: IIdentifier = identifiers[0];
    if (await this.session.hasPresentationDefinitions()) {
      const oid4vp: OID4VP = await this.session.getOID4VP();
      const credentialsAndDefinitions = credentials
        ? credentials
        : await oid4vp.filterCredentialsAgainstAllDefinitions();
      presentationsAndDefs = await oid4vp.createVerifiablePresentations(
        credentialsAndDefinitions,
        {
          identifierOpts: {
            identifier,
          },
        }
      );
      if (!presentationsAndDefs || presentationsAndDefs.length === 0) {
        throw Error('No verifiable presentations could be created');
      }
      identifier = presentationsAndDefs[0].identifierOpts.identifier;
    }
    const kid: string = (
      await getKey(identifier, 'authentication', this.session.context)
    ).kid;

    this.logRequest(true);

    return this.session.sendAuthorizationResponse({
      verifiablePresentations: presentationsAndDefs?.map(
        (pd) => pd.verifiablePresentation
      ),
      responseSignerOpts: { identifier, kid },
    });
  }

  /**
   * Persist the request of a specific relying party
   * @param jwt
   */
  private persistRequest(jwt: string, type: boolean, message: string) {
    const did = this.verifiedAuthorizationRequest.issuer;
    this.contactsService.findByDid(did);

    //based on the did in the jwt, we can find the relying party
    const relyingParty = this.contactsService.findByDid(did)!.url;
    //TODO: proprietary solution for now. Should be replaced with a proper storage in the agent
    const requests = this.getRequests();
    requests.push({
      jwt,
      relyingParty,
      date: new Date(),
      type,
      message,
    });
    localStorage.setItem('presentation_requests', JSON.stringify(requests));
  }

  /**
   * Request the presentation requests
   * @returns
   */
  public getRequests() {
    return JSON.parse(
      localStorage.getItem('presentation_requests') || '[]'
    ) as PresentationRequest[];
  }
}

export interface PresentationRequest {
  jwt: string;
  relyingParty: string;
  date: Date;
  type: boolean;
  message: string;
  //TODO: also store which information where sent
}
