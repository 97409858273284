<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div>Credentials</div>
  <button
    mat-icon-button
    [matMenuTriggerFor]="menu"
    aria-label="Example icon-button with a menu"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="setView()">
      <mat-icon>{{ showCards ? 'list' : 'photo_library' }}</mat-icon>
      <span>Show {{ showCards ? 'list' : 'cards' }}</span>
    </button>
  </mat-menu>
</mat-toolbar>
<div id="content" fxLayout="column" fxLayoutGap="16px">
  <mat-list *ngIf="!showCards">
    <mat-list-item
      *ngFor="let credential of credentials"
      [routerLink]="credential.hash"
    >
      <mat-icon matListItemIcon>note</mat-icon>
      <div matListItemTitle>
        {{ credentialService.getName(credential.verifiableCredential) }}
      </div>
      <div matListItemLine>
        {{ credential.verifiableCredential.issuanceDate | date }}
      </div>
    </mat-list-item>
  </mat-list>
  <div *ngIf="showCards" fxLayout="column" fxLayoutGap="16px">
    <bcrl-credentials-card
      [routerLink]="credential.hash"
      *ngFor="let credential of credentials"
      [credential]="credential"
    ></bcrl-credentials-card>
  </div>
</div>
