import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VerifiableCredential } from '@veramo/core';

@Component({
  selector: 'ssi-credentials-show-raw',
  templateUrl: './credentials-show-raw.component.html',
  styleUrls: ['./credentials-show-raw.component.scss'],
})
export class CredentialsShowRawComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public credential: VerifiableCredential
  ) {}
}
