import { Component, Input, OnInit } from '@angular/core';
import { VerifiableCredential } from '@veramo/core';

@Component({
  selector: 'ssi-credential-values',
  templateUrl: './credential-values.component.html',
  styleUrls: ['./credential-values.component.scss'],
})
export class CredentialValuesComponent implements OnInit {
  @Input() credential!: VerifiableCredential;

  @Input() issuer!: string;

  fields: { key: string; value: string }[] = [];

  ngOnInit(): void {
    const keys = Object.keys(this.credential.credentialSubject);
    // TODO: for the key field that is display we can use the saved values from the display.
    keys
      .filter((key) => key !== 'id')
      .forEach((key) => {
        this.fields.push({
          key,
          value: this.credential.credentialSubject[key],
        });
      });
  }
}
