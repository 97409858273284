import { Component, OnInit } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { environment } from '../../environments/environment';

@Component({
  selector: 'bcrl-settings',
  templateUrl: './settings.component.html',
  styleUrl: './settings.component.scss',
})
export class SettingsComponent implements OnInit {
  darkMode = false;

  build = environment.buildId;

  ngOnInit(): void {
    this.darkMode = localStorage.getItem('mode') === 'dark';
    if (this.darkMode && !document.body.classList.contains('darkMode')) {
      document.body.classList.add('darkMode');
    }
  }

  changeMode(event: MatSlideToggleChange) {
    if (event.checked) {
      this.darkMode = true;
      localStorage.setItem('mode', 'dark');
      document.body.classList.add('darkMode');
    } else {
      this.darkMode = false;
      localStorage.removeItem('mode');
      document.body.classList.remove('darkMode');
    }
  }

  delete() {
    if (
      !confirm(
        'This action will remove all data from your wallet. Are you sure?'
      )
    )
      return;
    localStorage.clear();
    window.location.reload();
  }
}
