<div
  *ngIf="credential"
  class="card"
  [ngStyle]="{
    'background-color': display![0].background_color,
    'background-image': 'url(' + display![0].background_image?.url + ')',
    width: width,
    height: height,
    color: display![0].text_color
  }"
  [ngClass]="{ list: type }"
  fxLayout="column"
  fxLayoutAlign="space-between stretch"
>
  <div class="header" fxLayout="row" fxLayoutAlign="space-between center">
    <img
      class="logo"
      [src]="display![0].logo?.url"
      [alt]="display![0].logo?.alt_text"
    />
    <div class="subject">{{ display![0].name }}</div>
  </div>
  <div class="footer" *ngIf="status">
    <div class="lastLine" fxLayout="row" fxLayoutAlign="space-between center">
      <div>{{ expire }}</div>
      <div class="status">{{ status }}</div>
    </div>
  </div>
</div>
