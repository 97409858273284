import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '../credentials.service';
import { UniqueVerifiableCredential } from '@veramo/core';
import { DisplayService } from '../display.service';
import { CredentialsSupportedDisplay } from '@sphereon/oid4vci-common';

export interface Cred extends UniqueVerifiableCredential {
  expire: string;
  display: CredentialsSupportedDisplay[];
}

@Component({
  selector: 'ssi-credentials-list',
  templateUrl: './credentials-list.component.html',
  styleUrls: ['./credentials-list.component.scss'],
})
export class CredentialsListComponent implements OnInit {
  showCards = false;
  credentials: UniqueVerifiableCredential[] = [];
  constructor(public credentialService: CredentialsService) {}

  async ngOnInit(): Promise<void> {
    this.showCards = localStorage.getItem('showCards') === 'true';
    this.credentials = await this.credentialService.list();
  }

  setView() {
    this.showCards = !this.showCards;
    localStorage.setItem('showCards', this.showCards.toString());
  }
}
