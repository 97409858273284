<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <button mat-icon-button routerLink="/contacts">
    <mat-icon>arrow_back_ios_new</mat-icon>
  </button>
</mat-toolbar>

<mat-list>
  <mat-list-item>
    <div matListItemTitle>{{ contact.name }}</div>
    <div matListItemLine>Name</div>
  </mat-list-item>
  <div matSubheader>Requests ({{ contact.requests.length }})</div>
  <mat-list-item *ngFor="let request of contact.requests">
    <mat-icon matListItemIcon *ngIf="request.type">done</mat-icon>
    <mat-icon matListItemIcon *ngIf="!request.type">close</mat-icon>
    <h4 matListItemTitle>{{ request.date | date : 'medium' }}</h4>
    <p matListItemLine>{{ request.message }}</p>
  </mat-list-item>
  <div matSubheader>DIDs</div>
  <mat-list-item *ngFor="let did of contact.did" class="did">
    <mat-icon matListItemIcon>fingerprint</mat-icon>
    <div matListItemTitle>{{ did }}</div>
  </mat-list-item>
</mat-list>
<!-- <mat-accordion>
  <mat-expansion-panel *ngFor="let contact of contacts">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{ contact.name }}
      </mat-panel-title>
      <mat-panel-description>{{ contact.url }}</mat-panel-description>
    </mat-expansion-panel-header>
    <h3>Dids</h3>
    <pre>{{ contact.did | json }}</pre>
    <h3>Requests</h3>
    <mat-list>
      <mat-list-item *ngFor="let request of contact.requests">
        <mat-icon matListItemIcon *ngIf="request.type">done</mat-icon>
        <mat-icon matListItemIcon *ngIf="!request.type">close</mat-icon>
        <h4 matListItemTitle>{{ request.date | date : 'medium' }}</h4>
        <p matListItemLine>{{ request.message }}</p>
      </mat-list-item>
    </mat-list>
    <button mat-button color="warn" (click)="remove(contact.url)">
      Remove
    </button>
  </mat-expansion-panel>
</mat-accordion> -->
