import { Injectable } from '@angular/core';
import {
  CommonCredentialSupported,
  CredentialSupportedJwtVcJsonLdAndLdpVc,
  CredentialsSupportedDisplay,
} from '@sphereon/oid4vci-common';

interface StoredDisplay {
  displayId: string;
  hash: string;
}

@Injectable({
  providedIn: 'root',
})
export class DisplayService {
  private storageValue = 'displays';
  private storageDisplayCredential = 'displayCredential';

  getDisplay(values: { hash?: string; types?: string[] }) {
    // get the correct display connection
    const connections = JSON.parse(
      localStorage.getItem(this.storageDisplayCredential) || '[]'
    ) as StoredDisplay[];
    if (values.hash) {
      const displayId = connections.find(
        (c) => c.hash === values.hash
      )?.displayId;
      return (
        this.getDisplays().find(
          (d) => d.id === displayId
        ) as CommonCredentialSupported
      ).display as CredentialsSupportedDisplay[];
    } else if (values.types) {
      return (
        this.getDisplays().find((d) =>
          d.types.every((type) => values.types?.includes(type))
        ) as CommonCredentialSupported
      ).display as CredentialsSupportedDisplay[];
    } else {
      throw Error();
    }
  }

  /**
   * Easy solution to store the display value in local storage for each credential. It would be better to make a mapping to save space.
   * @param hash
   * @param support
   */
  addDisplay(hash: string, support: CredentialSupportedJwtVcJsonLdAndLdpVc) {
    const displays = this.getDisplays();
    if (!displays.find((display) => display.id === support.id)) {
      displays.push(support);
    }
    // add the connection to the display
    const connections = JSON.parse(
      localStorage.getItem(this.storageDisplayCredential) || '[]'
    ) as StoredDisplay[];

    connections.push({
      hash,
      displayId: support.id as string,
    });
    localStorage.setItem(
      this.storageDisplayCredential,
      JSON.stringify(connections)
    );

    this.saveDisplays(displays);
  }

  removeDisplay(hash: string) {
    //TODO: impelement
    const displays = this.getDisplays();
    // const index = displays.findIndex((d) => d.hash === hash);
    // if (index > -1) {
    //   displays.splice(index, 1);
    // }
    this.saveDisplays(displays);
  }

  private saveDisplays(displays: CredentialSupportedJwtVcJsonLdAndLdpVc[]) {
    localStorage.setItem(this.storageValue, JSON.stringify(displays));
  }

  private getDisplays(): CredentialSupportedJwtVcJsonLdAndLdpVc[] {
    const displays = localStorage.getItem(this.storageValue);
    if (displays) {
      return JSON.parse(displays) as CredentialSupportedJwtVcJsonLdAndLdpVc[];
    }
    return [];
  }
}
