import { Injectable } from '@angular/core';
import { AgentService } from '../agent.service';
import {
  DIDDocument,
  DIDResolutionResult,
  IIdentifier,
  VerifiableCredential,
} from '@veramo/core';
import { Router } from '@angular/router';
import { ContactsService } from '../contacts/contacts.service';
import { OpenID4VCIClient } from '@sphereon/oid4vci-client';
import {
  encodeBase64url,
  decodeCredentialToObject,
  generateJwkFromVerificationMethod,
  JwkDidSupportedKeyTypes,
} from '@veramo/utils';
import {
  Alg,
  Jwt,
  ProofOfPossessionCallbacks,
  CredentialSupported,
  JwtVerifyResult,
  CredentialSupportedJwtVcJsonLdAndLdpVc,
} from '@sphereon/oid4vci-common';
import { DisplayService } from './display.service';
import { W3CVerifiableCredential } from '@sphereon/ssi-types';
import { VerificationMethod } from '@sphereon/did-uni-client';
import { StatusService } from './status.service';
import { importJWK, jwtVerify } from 'jose';

@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  private identifier!: IIdentifier;
  private did!: DIDResolutionResult;
  client!: OpenID4VCIClient;
  template?: CredentialSupported;
  credential?: VerifiableCredential;

  /**
   * The constructor function initializes private variables for the agent service, router, contacts
   * service, and display service.
   * @param {AgentService} agentService - An instance of the AgentService class, which likely provides
   * functionality related to managing agents or user accounts.
   * @param {Router} router - The router parameter is an instance of the Router service, which is used
   * for navigationg between different routes in an Angular application. It provides methods for
   * navigationg to a specific route, navigationg back, and other navigation-related functionality.
   * @param {ContactsService} contactsService - This parameter is of type ContactsService and is used
   * to interact with the contacts data in the application. It likely provides methods for retrieving,
   * creating, updating, and deleting contacts.
   * @param {DisplayService} displayService - The `displayService` parameter is an instance of the
   * `DisplayService` class. It is used to handle the display and rendering of data in the user
   * interface.
   */
  constructor(
    private agentService: AgentService,
    private router: Router,
    private contactsService: ContactsService,
    private displayService: DisplayService,
    private statusService: StatusService
  ) {}

  /**
   * The `requestCredential` function is used to initiate a credential issuance process by creating a
   * new DID, resolving the DID, retrieving server metadata, confirming the issuer, getting a token,
   * and finally getting the credential.
   * @param {string} uri - The `uri` parameter is a string that represents the URI (Uniform Resource
   * Identifier) of the OpenID Connect authorization request. This URI is typically provided by the
   * Issuer (the entity responsible for issuing the credentials) and can be in the form of a URL or a
   * QR code.
   * @returns The function `requestCredential` does not have a return statement, so it does not
   * explicitly return anything.
   */
  async requestCredential(uri: string) {
    // we create a new DID for the issuance for privacy reasons
    this.identifier = await this.agentService.agent.didManagerGetOrCreate({
      provider: 'did:jwk',
      alias: 'default',
      kms: 'local',
    });
    this.did = await this.agentService.agent.resolveDid({
      didUrl: this.identifier.did,
    });

    // The client is initiated from a URI. This URI is provided by the Issuer, typically as a URL or QR code.
    this.client = await OpenID4VCIClient.fromURI({
      uri,
      retrieveServerMetadata: true, // Already retrieve the server metadata. Can also be done afterwards by invoking a method yourself.
    });

    const cred = this.client.credentialOffer?.credential_offer.credentials[0];
    this.template = (
      this.client.endpointMetadata.credentialIssuerMetadata
        ?.credentials_supported as CredentialSupported[]
    ).find((credential) => credential.id === cred) as CredentialSupported;

    if (!(await this.contactsService.confirm(this.client.getIssuer()))) return;
    await this.getToken().then(
      async () => {
        this.credential = await this.getCredential();
      },
      () => this.router.navigate(['/'])
    );
  }

  /**
   * The `signCallback` function generates a signed JSON Web Token (JWT) using the provided payload and
   * header, and returns the signed JWT.
   * @param {Jwt} signArgs - The `signArgs` parameter is of type `Jwt`, which is an interface or type
   * that contains the following properties:
   * @returns a JWT (JSON Web Token) as a string.
   */
  private async signCallback(signArgs: Jwt): Promise<string> {
    const jwtPayload = {
      ...signArgs.payload,
      // Reduce the time in case the clocks are not synced.
      iat: new Date().getTime() / 1000 - 1000 * 60,
      exp: new Date().getTime() / 1000 + 1000 * 60 * 60,
      iss: this.identifier.did,
      sub: this.identifier.did,
    };
    if (
      !this.did.didDocument?.verificationMethod ||
      this.did.didDocument.verificationMethod.length === 0
    )
      throw new Error('No verification method found');
    const kid = this.did.didDocument.verificationMethod[0].id;
    const jwtHeader = {
      ...signArgs.header,
      alg: this.identifier.keys[0].type === 'Secp256k1' ? 'ES256K' : 'ES256',
      kid,
    };

    const signingInput = [
      encodeBase64url(JSON.stringify(jwtHeader)),
      encodeBase64url(JSON.stringify(jwtPayload)),
    ].join('.');
    const signature = await this.agentService.agent.keyManagerSignJWT({
      kid: this.identifier.keys[0].kid,
      data: signingInput,
    });
    const jwt = [signingInput, signature].join('.');
    return jwt;
  }

  /**
   * Verifies locally that the signature that should be send is valid
   * @param args
   * @returns
   */
  async verifyCallback(args: {
    jwt: string;
    kid?: string;
  }): Promise<JwtVerifyResult<DIDDocument>> {
    const key = this.identifier.keys[0];
    const jwk = generateJwkFromVerificationMethod(
      key.type as JwkDidSupportedKeyTypes,
      this.did.didDocument!.verificationMethod![0]!
    )!;
    const publicKey = await importJWK(jwk);
    //TODO: in case it fails, we should inform the user. maybe it's okay to start the process again with a new generated key.
    const result = await jwtVerify(args.jwt, publicKey).catch((err) => {
      alert(`failed to verify signature with key: ${key.kid}`);
      throw new Error(err);
    });
    const kid = result.protectedHeader.kid ?? args.kid;
    const did = kid!.split('#')[0];
    const didDocument: DIDDocument = {
      '@context': 'https://www.w3.org/ns/did/v1',
      id: did,
    };
    const alg = result.protectedHeader.alg;
    return {
      alg,
      kid,
      did,
      didDocument,
      jwt: {
        header: result.protectedHeader,
        payload: result.payload,
      },
    };
  }

  /**
   * The function `getToken` acquires an access token using a PIN and throws an error if the PIN is
   * expired.
   * @param {string} [pin] - The `pin` parameter is a string that represents the pin code used to
   * acquire an access token.
   * @returns The `getToken` function returns a promise that resolves to the access token acquired by
   * the `this.client.acquireAccessToken` method.
   */
  getToken(pin?: string) {
    return this.client.acquireAccessToken({ pin }).catch((e) => {
      if (e.message.includes('400')) {
        throw new Error('qr code expired');
      }
    });
  }

  /**
   * The function `getCredential` acquires credentials using the OID4VCI client and returns the decoded
   * verifiable credential object.
   * @returns The function `getCredential()` returns a Promise that resolves to a decoded
   * W3CVerifiableCredential object.
   */
  async getCredential() {
    const callbacks: ProofOfPossessionCallbacks<DIDDocument> = {
      signCallback: this.signCallback.bind(this),
      verifyCallback: this.verifyCallback.bind(this),
    };
    // use types and format from the credential issuer metadata
    return this.client
      .acquireCredentials({
        credentialTypes: (
          this.template as CredentialSupportedJwtVcJsonLdAndLdpVc
        ).types,
        proofCallbacks: callbacks,
        format: (this.template as CredentialSupported).format,
        alg: Alg.ES256K,
        kid: (
          (this.did.didDocument as DIDDocument)
            .verificationMethod as VerificationMethod[]
        )[0].id,
      })
      .then((res) =>
        decodeCredentialToObject(res.credential as W3CVerifiableCredential)
      );
  }

  /**
   * The function stores a verifiable credential, adds it to a display, stores the issuer's DID, and
   * navigates to the credentials page.
   */
  async storeCredential() {
    const verifiableCredential = this.credential as VerifiableCredential;
    const hash =
      await this.agentService.agent.dataStoreSaveVerifiableCredential({
        verifiableCredential,
      });
    this.displayService.addDisplay(
      hash,
      this.template as CredentialSupportedJwtVcJsonLdAndLdpVc
    );
    // also store the issuer's did
    this.contactsService.addDid(
      verifiableCredential.issuer as string,
      this.client.getIssuer()
    );
    this.router.navigate(['/credentials', hash]);
  }

  /**
   * The function `getName` takes a `VerifiableCredential` object as input and returns the second
   * element of the `type` array if it exists, otherwise it returns the first element.
   * @param {VerifiableCredential} credential - The parameter "credential" is of type
   * "VerifiableCredential".
   * @returns the value of the `type` property of the `credential` object. If the `type` property is an
   * array with more than one element, it returns the second element of the array. Otherwise, it
   * returns the value of the `type` property itself.
   */
  getName(credential: VerifiableCredential) {
    if (Array.isArray(credential.type) && credential.type.length > 1)
      return credential.type[1];
    return credential.type;
  }

  /**
   * The function returns a list of verifiable credentials from the data store.
   * @returns the verifiable credentials stored in the data store.
   */
  list() {
    return this.agentService.agent.dataStoreORMGetVerifiableCredentials();
  }

  /**
   * The function retrieves a verifiable credential from the data store based on a given hash.
   * @param {string} hash - The `hash` parameter is a string that represents the hash value of a
   * verifiable credential.
   * @returns the result of calling the `dataStoreGetVerifiableCredential` method on the `agent` object
   * with the provided `hash` as an argument.
   */
  get(hash: string) {
    return this.agentService.agent.dataStoreGetVerifiableCredential({ hash });
  }

  /**
   * The function deletes a display and a verifiable credential from the data store.
   * @param {string} hash - The `hash` parameter is a string that represents the unique identifier of
   * the verifiable credential that needs to be deleted.
   * @returns the result of the `agent.dataStoreDeleteVerifiableCredential` method, which is likely a
   * promise or an asynchronous operation.
   */
  delete(hash: string) {
    this.displayService.removeDisplay(hash);
    return this.agentService.agent.dataStoreDeleteVerifiableCredential({
      hash,
    });
  }

  /**
   * The function "expire" checks if a given credential has an expiration date and returns either
   * "never expires", "expired", or the expiration date in a localized string format.
   * @param {VerifiableCredential} credential - The `credential` parameter is an object that represents
   * a verifiable credential. It contains information about the credential, including an expiration
   * date.
   * @returns The function `expire` returns one of the following:
   */
  expire(credential: VerifiableCredential) {
    if (!credential.expirationDate) return 'never expires';
    const date = new Date(credential.expirationDate);
    if (date < new Date()) return 'expired';
    return date.toLocaleDateString();
  }

  /**
   * The function checks the status of a verifiable credential and returns either 'valid' or
   * 'checking'.
   * @param {VerifiableCredential} credential - The parameter "credential" is of type
   * "VerifiableCredential".
   * @returns If the `credential` object does not have a `credentialStatus` property, the function will
   * return 'valid'. Otherwise, it will return 'checking'.
   */
  status(credential: VerifiableCredential): Promise<string> {
    return this.statusService.queryStatus(credential);
  }
}
