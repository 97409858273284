import { Component, Input } from '@angular/core';
import { CredentialsSupportedDisplay } from '@sphereon/oid4vci-common';

@Component({
  selector: 'ssi-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() display!: CredentialsSupportedDisplay[];

  @Input() expire?: string;

  @Input() status?: string;

  @Input() height?: string;

  @Input() width?: string;

  @Input() type?: string;
}
