<div id="scanner" *ngIf="!found">
  <div id="menu" *ngIf="activeCamera">
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button
        mat-menu-item
        *ngFor="let camera of cameras"
        (click)="changeCamera(camera)"
        [disabled]="camera.deviceId === activeCamera.deviceId"
        [ngClass]="{
          'active-camera': camera.deviceId === activeCamera.deviceId
        }"
      >
        <span>{{ camera.label }}</span>
      </button>
    </mat-menu>
  </div>
  <div id="overview" fxLayout="row" fxLayoutAlign="center center">
    <div id="field"></div>
  </div>
  <zxing-scanner
    #scanner
    (scanSuccess)="scanned($event)"
    (camerasFound)="foundCameras($event)"
    (camerasNotFound)="noCamerasFound()"
    [(device)]="activeCamera"
  ></zxing-scanner>
</div>
<ssi-credential-request
  *ngIf="found && credentialService.credential"
></ssi-credential-request>
<ssi-credentials-select
  *ngIf="found && presentationService.res"
></ssi-credentials-select>
