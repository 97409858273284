import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ContactsService } from '../contacts.service';

@Component({
  selector: 'ssi-contacts-new',
  templateUrl: './contacts-new.component.html',
  styleUrls: ['./contacts-new.component.scss'],
})
export class ContactsNewComponent {
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: string,
    private dialogRef: MatDialogRef<ContactsNewComponent>,
    private contactService: ContactsService
  ) {
    this.form = new FormGroup({
      name: new FormControl(this.data, Validators.required),
      accept: new FormControl(true, Validators.requiredTrue),
    });
  }

  async add() {
    this.contactService.addContact(this.form.get('name')?.value, this.data);
    this.dialogRef.close(true);
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
