<mat-list>
  <div matListItemLine>Subject</div>
  <mat-list-item *ngFor="let field of fields">
    <div matListItemLine>{{ field.key }}</div>
    <div matListItemTitle>{{ field.value }}</div>
  </mat-list-item>
  <div matListItemLine>Issuer</div>
  <mat-list-item>
    <div matListItemLine>Issued by</div>
    <div matListItemTitle>{{ issuer }}</div>
  </mat-list-item>
</mat-list>
