import { Injectable } from '@angular/core';
import { AgentService } from '../agent.service';
import { VerifiableCredential } from '@veramo/core';

interface StatusEntry {
  revoked: boolean;
  validUntil: string;
}

@Injectable({
  providedIn: 'root',
})
export class StatusService {
  private entries: Map<string, StatusEntry>;

  constructor(private agentService: AgentService) {
    this.entries = new Map();
  }

  async queryStatus(credential: VerifiableCredential): Promise<string> {
    //TODO: also check the validUntil value to mark it as expired
    if (this.entries.has(credential.id as string)) {
      const entry = this.entries.get(credential.id as string) as StatusEntry;
      const valid = new Date(entry.validUntil);
      if (valid > new Date()) return entry.revoked ? 'revoked' : 'valid';
    }
    const status = await this.agentService.agent.checkCredentialStatus({
      credential,
    });
    const validUntil = new Date();
    validUntil.setDate(validUntil.getDate() + 1);
    this.entries.set(credential.id as string, {
      revoked: status.revoked,
      validUntil: validUntil.toISOString(),
    });
    //TODO: store the map in a storage
    return status.revoked ? 'revoked' : 'valid';
  }
}
