<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div>Required credentials</div>
</mat-toolbar>
<div class="info" fxLayout="column" fxLayoutGap="16px">
  <p>
    {{ name }} would like to receive the following information from you for
    verification:
  </p>
  <div *ngFor="let response of responses">
    <h4>{{ response.purpose }}</h4>
    <mat-radio-group [formControl]="response.control">
      <div *ngFor="let credential of response.credentials">
        <mat-radio-button
          *ngIf="credential.display"
          [value]="credential.credential"
        >
          <ssi-card
            width="300px"
            height="150px"
            [display]="credential.display"
            [expire]="this.credentialsService.expire(credential.credential)"
          ></ssi-card>
        </mat-radio-button>
        <!-- TODO: show values with a click on the button -->
        <!-- <ssi-credential-values
          [credential]="credential.credential"
          [issuer]="credential.issuer"
          ></ssi-credential-values> -->
      </div>
    </mat-radio-group>
  </div>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button color="warn" (click)="decline()">Decline</button>
    <button
      mat-raised-button
      color="primary"
      (click)="accept()"
      [disabled]="!isValid()"
    >
      Accept
    </button>
  </div>
</div>
