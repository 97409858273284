import { Route } from '@angular/router';
import { SettingsComponent } from './settings/settings.component';

export const appRoutes: Route[] = [
  {
    path: '',
    redirectTo: '/credentials',
    pathMatch: 'full',
  },
  {
    path: 'settings',
    component: SettingsComponent,
  },
];
