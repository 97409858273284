import { Injectable } from '@angular/core';
import {
  createAgent,
  IDataStore,
  IDataStoreORM,
  IDIDManager,
  IKeyManager,
  IResolver,
  TAgent,
} from '@veramo/core';

// Core identity manager plugin. This allows you to create and manage DIDs by orchestrating different DID provider packages.
// This implements `IDIDManager`
import { DIDManager } from '@veramo/did-manager';

// Core key manager plugin. DIDs use keys and this key manager is required to know how to work with them.
// This implements `IKeyManager`
import { KeyManager } from '@veramo/key-manager';

// A key management system that uses a local database to store keys (used by KeyManager)
import { KeyManagementSystem, SecretBox } from '@veramo/kms-local';

// Storage plugin using TypeORM to link to a database

import {
  DataStoreJson,
  DIDStoreJson,
  KeyStoreJson,
  BrowserLocalStorageStore,
  PrivateKeyStoreJson,
} from '@veramo/data-store-json';

import { WebDIDProvider } from '@veramo/did-provider-web';
import { getDidJwkResolver } from '@veramo/did-provider-jwk';
import { JwkDIDProvider } from '@sphereon/ssi-sdk-ext.did-provider-jwk';
import { getDidKeyResolver, KeyDIDProvider } from '@veramo/did-provider-key';

// Core DID resolver plugin. This plugin orchestrates different DID resolver drivers to resolve the corresponding DID Documents for the given DIDs.
// This plugin implements `IResolver`
import { DIDResolverPlugin, getUniversalResolver } from '@veramo/did-resolver';
// the did:web resolver package
import { getResolver as webDidResolver } from 'web-did-resolver';
// imports:
// This plugin allows us to issue and verify W3C Verifiable Credentials with JWT proof format
import { CredentialPlugin } from '@veramo/credential-w3c';
import {
  IDidAuthSiopOpAuthenticator,
  DidAuthSiopOpAuthenticator,
} from '@sphereon/ssi-sdk.siopv2-oid4vp-op-auth';
import { Resolver } from 'did-resolver';
import { CredentialStatusPlugin } from '@veramo/credential-status';
import { RemoteKeyManagementSystem } from './remote/key-management-system';
import { HttpClient } from '@angular/common/http';
import { AgentCore, AgentCoreType } from '@bcrl/agent-core';

type AgentType = AgentCoreType &
  IDIDManager &
  IKeyManager &
  IDataStore &
  IDataStoreORM &
  IResolver &
  IDidAuthSiopOpAuthenticator;

const uniResolver = getUniversalResolver();

export const didResolver = new Resolver({
  ...webDidResolver(),
  ...getDidKeyResolver(),
  ...getDidJwkResolver(),
  ebsi: uniResolver,
});

export const didMethodsSupported = Object.keys(didResolver['registry']).map(
  (method) => method.toLowerCase().replace('did:', '')
);
export const DID_PREFIX = 'did';
export enum SupportedDidMethodEnum {
  DID_KEY = 'key',
  DID_JWK = 'jwk',
  DID_WEB = 'web',
}

export enum KeyManagementSystemEnum {
  LOCAL = 'local',
}

export const didProviders = {
  [`${DID_PREFIX}:${SupportedDidMethodEnum.DID_WEB}`]: new WebDIDProvider({
    defaultKms: KeyManagementSystemEnum.LOCAL,
  }),
  [`${DID_PREFIX}:${SupportedDidMethodEnum.DID_KEY}`]: new KeyDIDProvider({
    defaultKms: KeyManagementSystemEnum.LOCAL,
  }),
  [`${DID_PREFIX}:${SupportedDidMethodEnum.DID_JWK}`]: new JwkDIDProvider({
    defaultKms: KeyManagementSystemEnum.LOCAL,
  }),
};

export const didProviderKeys = Object.keys(didProviders);

@Injectable({
  providedIn: 'root',
})
export class AgentService extends AgentCore {
  override agent!: TAgent<AgentType>;

  constructor(private httpClient: HttpClient) {
    super();
  }

  async init() {
    // TODO:use a real secret key to make it unique
    // const key = await SecretBox.createSecretKey();
    const key =
      '1fc7eb696ebaa38ee8fe709e3fd25491ef80f4b84c767fb0bb04bdfe5db56115';
    const dataStore = BrowserLocalStorageStore.fromLocalStorage('veramo-state');
    const identifierDataStore =
      BrowserLocalStorageStore.fromLocalStorage('veramo-id-state');
    const dataStoreConst = new DataStoreJson(dataStore);
    const keyStore = new KeyStoreJson(dataStore);
    const privateKeyStore = new PrivateKeyStoreJson(
      dataStore,
      new SecretBox(key)
    );
    const didStore = new DIDStoreJson(identifierDataStore);

    // const SQL = await initSqlJs({
    //   // Required to load the wasm binary asynchronously. Of course, you can host it wherever you want
    //   // You can omit locateFile completely when running in node
    //   locateFile: (file) => `https://sql.js.org/dist/${file}`,
    // });
    // window.SQL = SQL;
    // const dbConnection = new DataSource({
    //   type: 'sqljs',
    //   database: new Uint8Array(),
    //   location: 'contact-stroage',
    //   autoSave: true,
    //   synchronize: true,
    //   logger: 'debug',
    //   entities: [...DataStoreContactEntities, ...Entities],
    // });
    // await dbConnection.initialize();

    // Veramo agent setup
    this.agent = createAgent<AgentType>({
      plugins: [
        new KeyManager({
          store: keyStore,
          kms: {
            local: new KeyManagementSystem(privateKeyStore),
            remote: new RemoteKeyManagementSystem(this.httpClient),
          },
        }),
        new DIDManager({
          store: didStore,
          defaultProvider: SupportedDidMethodEnum.DID_KEY,
          providers: didProviders,
        }),
        dataStoreConst,
        new DIDResolverPlugin({
          resolver: didResolver,
        }),
        new CredentialPlugin(),
        new DidAuthSiopOpAuthenticator(),
        new CredentialStatusPlugin({
          StatusList2021Entry: this.validateStatusList2021.bind(this),
        }),
      ],
    });
  }
}
