import { Component, OnInit } from '@angular/core';
import { ContactsService, Contact } from './contacts.service';

@Component({
  selector: 'ssi-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent implements OnInit {
  contacts!: Contact[];
  constructor(public contactsService: ContactsService) {}

  ngOnInit(): void {
    this.contacts = this.contactsService.load();
  }

  async remove(url: string) {
    if (!confirm('Are you sure you want to remove this contact?')) {
      return;
    }
    this.contactsService.remove(url);
    this.contacts = this.contactsService.load();
  }
}
