import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CredentialsRoutingModule } from './credentials-routing.module';
import { CredentialsListComponent } from './credentials-list/credentials-list.component';
import { CredentialsShowComponent } from './credentials-show/credentials-show.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { ContactsModule } from '../contacts/contacts.module';
import { CredentialRequestComponent } from './credential-request/credential-request.component';
import { SharedFrontendModule } from '@bcrl/shared-frontend';
import { CredentialsService } from './credentials.service';
import { DisplayService } from './display.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SettingsModule } from '../settings/settings.module';
import { CredentialsSelectComponent } from './credentials-select/credentials-select.component';
import { MatRadioModule } from '@angular/material/radio';
import { StatusService } from './status.service';
import { CredentialsCardComponent } from './credentials-card/credentials-card.component';

@NgModule({
  declarations: [
    CredentialsListComponent,
    CredentialsShowComponent,
    CredentialRequestComponent,
    CredentialsSelectComponent,
    CredentialsCardComponent,
  ],
  imports: [
    CommonModule,
    SharedFrontendModule,
    SettingsModule,
    CredentialsRoutingModule,
    ContactsModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    FlexLayoutModule,
    MatListModule,
    MatToolbarModule,
    HttpClientModule,
    MatInputModule,
    FormsModule,
    MatSlideToggleModule,
    MatRadioModule,
  ],
  providers: [CredentialsService, DisplayService, StatusService],
  exports: [CredentialRequestComponent, CredentialsSelectComponent],
})
export class CredentialsModule {}
