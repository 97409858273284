import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '../credentials.service';
import { Contact, ContactsService } from '../../contacts/contacts.service';
import { Router } from '@angular/router';

@Component({
  selector: 'ssi-credential-request',
  templateUrl: './credential-request.component.html',
  styleUrls: ['./credential-request.component.scss'],
})
export class CredentialRequestComponent implements OnInit {
  issuer!: string;

  constructor(
    public credentialsService: CredentialsService,
    private contactsService: ContactsService,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.issuer = (
      (await this.contactsService.getByUrl(
        this.credentialsService.client.endpointMetadata.issuer
      )) as Contact
    ).name;
  }

  decline() {
    this.router.navigate(['/']);
  }

  accept() {
    this.credentialsService.storeCredential();
  }
}
