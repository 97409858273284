<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div>Settings</div>
</mat-toolbar>
<div id="content" fxLayout="column" fxLayoutGap="16px">
  <mat-slide-toggle [checked]="darkMode" (change)="changeMode($event)"
    >Dark Mode</mat-slide-toggle
  >
  <mat-list>
    <mat-list-item>
      <div matListItemTitle>{{ build }}</div>
      <div matListItemLine>Build</div>
    </mat-list-item>
  </mat-list>
  <mat-divider></mat-divider>
  <h3>Danger zone</h3>
  <button mat-button color="warn" (click)="delete()">
    <mat-icon>delete</mat-icon>
    <span>Delete wallet</span>
  </button>
</div>
