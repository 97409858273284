<h1 matDialogTitle>New contact detected</h1>

<form [formGroup]="form" (submit)="add()">
  <mat-dialog-content>
    <p>
      This new contact is not in your list yet. Give it a name to complete the
      exchange.
    </p>
    <div fxLayout="column" fxLayoutAlign="start stretch">
      <mat-form-field [appearance]="'outline'">
        <mat-label>Name</mat-label>
        <input matInput placeholder="Name" formControlName="name" />
      </mat-form-field>
      <mat-checkbox formControlName="accept">Save to contacts</mat-checkbox>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button type="button" (click)="cancel()">Decline</button>
    <button mat-button color="primary" type="submit" [disabled]="form.invalid">
      Accept
    </button>
  </mat-dialog-actions>
</form>
