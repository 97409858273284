<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <div>Credential offer</div>
</mat-toolbar>
<div fxLayout="column" fxLayoutAlign="" fxLayoutGap="16px" class="content">
  <ssi-card
    *ngIf="credentialsService.template"
    [display]="credentialsService.template!.display!"
  ></ssi-card>
  <h3>Values</h3>
  <ssi-credential-values
    [issuer]="issuer"
    [credential]="credentialsService.credential!"
  ></ssi-credential-values>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <button mat-button color="warn" (click)="decline()">Decline</button>
    <button mat-raised-button color="primary" (click)="accept()">Accept</button>
  </div>
</div>
