import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CredentialsListComponent } from './credentials-list/credentials-list.component';
import { CredentialsShowComponent } from './credentials-show/credentials-show.component';

const routes: Routes = [
  {
    path: 'credentials',
    children: [
      {
        path: '',
        component: CredentialsListComponent,
      },
      {
        path: ':id',
        component: CredentialsShowComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CredentialsRoutingModule {}
