import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Contact, ContactsService } from '../contacts.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'bcrl-contacts-show',
  templateUrl: './contacts-show.component.html',
  styleUrl: './contacts-show.component.scss',
})
export class ContactsShowComponent implements OnInit {
  contact!: Contact;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private contactsService: ContactsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id') as string;
    const contact = this.contactsService.findByDid(id);
    if (contact === undefined) {
      // this.router.navigate(['/contacts']);
      this.snackBar.open('Contact not found', 'Close', { duration: 3000 });
    }
    this.contact = contact as Contact;
  }
}
