<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  Contacts
</mat-toolbar>
<mat-list>
  <mat-list-item *ngFor="let contact of contacts" [routerLink]="contact.did[0]">
    <mat-icon matListItemIcon>person</mat-icon>
    <div matListItemTitle>{{ contact.name }}</div>
  </mat-list-item>
</mat-list>
