import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { ContactsNewComponent } from './contacts-new/contacts-new.component';
import { Router } from '@angular/router';
import {
  PresentationRequest,
  PresentationService,
} from '../credentials/presentation.service';

export interface Contact {
  // url of the contact
  url: string;
  // did of the contact
  did: string[];
  // human readable name
  name: string;

  requests: PresentationRequest[];
}

@Injectable({
  providedIn: 'root',
})
export class ContactsService {
  private contactStorage = 'contacts';

  constructor(private dialog: MatDialog, private router: Router) {}

  public load(): Contact[] {
    const contacts = JSON.parse(
      localStorage.getItem(this.contactStorage) || '[]'
    );
    const requests = JSON.parse(
      localStorage.getItem('presentation_requests') || '[]'
    ) as PresentationRequest[];
    return contacts.map((contact) => ({
      ...contact,
      requests: requests.filter(
        (request) => request.relyingParty === contact.url
      ),
    }));
  }

  private store(contacts: Contact[]) {
    localStorage.setItem(this.contactStorage, JSON.stringify(contacts));
  }

  getByUrl(url: string) {
    return this.load().find((c) => c.url === url);
  }

  getNameByDid(did: string) {
    return this.findByDid(did)?.name as string;
  }

  findByDid(did: string) {
    return this.load().find((c) => c.did.includes(did));
  }

  addContact(name: string, url: string) {
    const contacts = this.load();
    contacts.push({ name, url, did: [], requests: [] });
    this.store(contacts);
  }

  /**
   * Adds a new did to the contact
   * @param did
   * @param url
   */
  addDid(did: string, url: string) {
    const contacts = this.load();
    const index = contacts.findIndex((c) => c.url === url);
    if (index === -1)
      throw Error(`contact not found for ${did} with url ${url}`);
    contacts[index].did.find((d) => d === did) || contacts[index].did.push(did);
    this.store(contacts);
  }

  remove(url: string) {
    const contacts = this.load();
    const index = contacts.findIndex((c) => c.url === url);
    contacts.splice(index, 1);
    this.store(contacts);
  }

  async confirm(issuer: string) {
    const found = this.load().find((c) => c.url === issuer);
    if (found) return true;
    const proceed = await firstValueFrom(
      this.dialog
        .open(ContactsNewComponent, {
          data: issuer,
          disableClose: true,
        })
        .afterClosed()
    );
    if (!proceed) {
      this.router.navigate(['/credentials']);
      // throw Error('user denied credential offer');
      return false;
    }
    return true;
  }
}
