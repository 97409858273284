import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsNewComponent } from './contacts-new/contacts-new.component';
import { ContactsComponent } from './contacts.component';
import { ContactsService } from './contacts.service';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { SettingsModule } from '../settings/settings.module';
import { ContactsRoutingModule } from './contacts-routing.module';
import { ContactsShowComponent } from './contacts-show/contacts-show.component';

@NgModule({
  declarations: [
    ContactsComponent,
    ContactsNewComponent,
    ContactsShowComponent,
  ],
  providers: [ContactsService],
  imports: [
    CommonModule,
    SettingsModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    FlexLayoutModule,
    MatDialogModule,
    MatIconModule,
    MatExpansionModule,
    ContactsRoutingModule,
  ],
  exports: [ContactsNewComponent],
})
export class ContactsModule {}
