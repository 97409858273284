import { Component, OnInit } from '@angular/core';
import { CredentialsService } from '../credentials.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UniqueVerifiableCredential } from '@veramo/core';
import { CredentialsSupportedDisplay } from '@sphereon/oid4vci-common';
import { DisplayService } from '../display.service';
import { ContactsService } from '../../contacts/contacts.service';
import { CredentialsShowRawComponent } from '@bcrl/shared-frontend';
import { MatDialog } from '@angular/material/dialog';
import { AgentService } from '../../agent.service';

@Component({
  selector: 'ssi-credentials-show',
  templateUrl: './credentials-show.component.html',
  styleUrls: ['./credentials-show.component.scss'],
})
export class CredentialsShowComponent implements OnInit {
  credential!: UniqueVerifiableCredential;
  id: string;
  issuer!: string;
  status?: string;
  display?: CredentialsSupportedDisplay[];
  expire: string | undefined;
  constructor(
    public credentialService: CredentialsService,
    private route: ActivatedRoute,
    private router: Router,
    private displayService: DisplayService,
    private contactsService: ContactsService,
    private dialog: MatDialog,
    private agentService: AgentService
  ) {
    this.id = this.route.snapshot.paramMap.get('id') as string;
  }

  async ngOnInit(): Promise<void> {
    this.credential = {
      hash: this.id,
      verifiableCredential: await this.credentialService.get(this.id),
    };
    this.display = this.displayService.getDisplay({ hash: this.id });
    // this.issuer = this.contactsService.getNameByDid(
    //   this.credential.verifiableCredential.issuer as string
    // );
    this.issuer = this.credential.verifiableCredential.issuer as string;
    this.status = await this.credentialService.status(
      this.credential.verifiableCredential
    );
    this.expire = this.credentialService.expire(
      this.credential.verifiableCredential
    );
  }

  showRaw() {
    this.dialog.open(CredentialsShowRawComponent, { data: this.credential });
  }

  async remove() {
    if (!confirm('Are you sure you want to remove this credential?')) return;
    await this.credentialService.delete(this.id);
    this.router.navigate(['/credentials']);
  }
}
