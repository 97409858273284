<mat-toolbar
  color="primary"
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <button mat-icon-button routerLink="/credentials">
    <mat-icon>arrow_back_ios_new</mat-icon>
  </button>
  <button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item color="primary" (click)="showRaw()">
      <mat-icon>code</mat-icon>
      <span>Show raw</span>
    </button>
    <button mat-menu-item color="warn" (click)="remove()">
      <mat-icon>delete</mat-icon>
      <span>Delete</span>
    </button>
  </mat-menu>
</mat-toolbar>
<div
  id="content"
  fxLayout="column"
  fxLayoutGap="16px"
  fxLayoutAlign="start stretch"
  *ngIf="credential"
>
  <bcrl-credentials-card
    *ngIf="display"
    [credential]="credential"
  ></bcrl-credentials-card>
  <ssi-credential-values
    [credential]="credential.verifiableCredential"
    [issuer]="issuer"
  ></ssi-credential-values>
</div>
